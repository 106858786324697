import SponsorMap from '@/assets/img/corporate-and-organization/sponsorship_map@2x.png';
import SponsorMapM from '@/assets/img/corporate-and-organization/sponsorship_mapM@2x.png';
import Sponsorship03a from '@/assets/img/corporate-and-organization/sponsorship_img-03-a.jpg';
import Sponsorship03aX2 from '@/assets/img/corporate-and-organization/sponsorship_img-03-a@2x.jpg';
import Sponsorship03b from '@/assets/img/corporate-and-organization/sponsorship_img-03-b.jpg';
import Sponsorship03bX2 from '@/assets/img/corporate-and-organization/sponsorship_img-03-b@2x.jpg';
import Sponsorship04a from '@/assets/img/corporate-and-organization/sponsorship_img-04-a.jpg';
import Sponsorship04aX2 from '@/assets/img/corporate-and-organization/sponsorship_img-04-a@2x.jpg';
import Sponsorship05a from '@/assets/img/corporate-and-organization/sponsorship_img-05-a.jpg';
import Sponsorship05b from '@/assets/img/corporate-and-organization/sponsorship_img-05-b.jpg';
import Sponsorship05bX2 from '@/assets/img/corporate-and-organization/sponsorship_img-05-b@2x.jpg';
import Sponsorship05c from '@/assets/img/corporate-and-organization/sponsorship_img-05-c.jpg';
import Sponsorship05cX2 from '@/assets/img/corporate-and-organization/sponsorship_img-05-c@2x.jpg';
import Sponsorship06a from '@/assets/img/corporate-and-organization/sponsorship_img-06-a.jpg';
import Sponsorship06aX2 from '@/assets/img/corporate-and-organization/sponsorship_img-06-a@2x.jpg';
import Sponsorship07a from '@/assets/img/corporate-and-organization/sponsorship_img-07-a.jpg';
import Sponsorship07aX2 from '@/assets/img/corporate-and-organization/sponsorship_img-07-a@2x.jpg';
import Sponsorship07b from '@/assets/img/corporate-and-organization/sponsorship_img-07-b.jpg';
import Sponsorship07bX2 from '@/assets/img/corporate-and-organization/sponsorship_img-07-b@2x.jpg';
import Sponsorship07c from '@/assets/img/corporate-and-organization/sponsorship_img-07-c.jpg';
import Sponsorship07cX2 from '@/assets/img/corporate-and-organization/sponsorship_img-07-c@2x.jpg';
import Sponsorship07d from '@/assets/img/corporate-and-organization/sponsorship_img-07-d.jpg';
import Sponsorship07dX2 from '@/assets/img/corporate-and-organization/sponsorship_img-07-d@2x.jpg';
import Sponsorship07e from '@/assets/img/corporate-and-organization/sponsorship_img-07-e.jpg';
import Sponsorship07eX2 from '@/assets/img/corporate-and-organization/sponsorship_img-07-e@2x.jpg';
import Sponsorship08a from '@/assets/img/corporate-and-organization/sponsorship_img-08-a.jpg';
import Sponsorship08aX2 from '@/assets/img/corporate-and-organization/sponsorship_img-08-a@2x.jpg';
import Sponsorship08b from '@/assets/img/corporate-and-organization/sponsorship_img-08-b.jpg';
import Sponsorship08bX2 from '@/assets/img/corporate-and-organization/sponsorship_img-08-b@2x.jpg';

import ContactUs from '@/components/ContactUs';
import Container from '@/components/Container';
import Image from '@/components/Image';
import PageComponent from '@/components/PageComponent';
import Section from '@/components/Section';
import SectionHeader from '@/components/SectionHeader';
import { Tit } from '@/components/Titles';
import { breakpoint } from '@/helpers/BreakpointHelper';
import { PageProps } from 'gatsby';
import React, { FC } from 'react';
import styled from 'styled-components';
import AccordionComponent, { AccordionItem } from '@/components/Accordion';

const SponsorData = [
  {
    id: 1,
    title: `유니세프 원주시후원회`,
    dec: `유니세프 원주시후원회는 2001년 12월 창립되었습니다. 2003년 6월 이라크 어린이를 돕기 ‘앙드레 김’ 자선 패션쇼를 개최하여 기금을 마련하였고, 2014년 조수미 콘서트 개최 수익금으로 기금을 조성하는 등 다양한 문화공연을 통해 지역민들과 함께 유니세프 사업을 적극 지원하고 있습니다.`,
    active: [
      {
        activeName: `2003년 5월 앙드레김 자선 패션쇼 기금 전달`,
        pcImg: null,
        mobileImg: null,
      },
      {
        activeName: `2005년 1월 남아시아 지진,해일 피해 아동 구호 기금 전달`,
        pcImg: null,
        mobileImg: null,
      },
      {
        activeName: `2008년 4월 난치성 질환 원주 어린이 돕기 후원금 지원`,
        pcImg: null,
        mobileImg: null,
      },
      {
        activeName: `2014년 12월 소프라노 조수미 초청 자선공연 기금 전달`,
        pcImg: null,
        mobileImg: null,
      },
    ],
  },
  {
    id: 2,
    title: `유니세프 금산군후원회`,
    dec: `유니세프 금산군후원회는 2003년 8월 창립되었습니다. 2004년 9월 지구촌 어린이 웃는 얼굴 그리기 전시회를 시작하여 2014년까지 전시회를 통해 유니세프를 홍보하고 기금을 조성하였습니다. 이후 꾸준히 지역 축제는 통한 참여형 유니세프 홍보 부스 운영으로 유니세프 사업에 적극 동참하고 있습니다.`,
    active: [
      {
        activeName: `2004년 – 2014년 지구촌 웃는 얼굴 그리기 대회 전시회 개최를 통한 모금`,
        pcImg: null,
        mobileImg: null,
      },
    ],
  },
  {
    id: 3,
    title: `유니세프 부산시후원회`,
    dec: `유니세프 부산시후원회는 2007년 1월 창립되었습니다. 2011년 5월 저소득층 어린이를 초청해 어린이날 큰잔치 행사를 개최하였으며, 이후 부산시 아동권리 옹호 활동에 적극 동참하고 있습니다.`,
    active: [
      {
        activeName: `2011년 – 2024년 저소득층 아동 초청 어린이날 큰잔치 개최`,
        pcImg: Sponsorship03aX2,
        mobileImg: Sponsorship03a,
      },
      {
        activeName: `2021년 10월 코로나19 극복을 위한 저소득층 아동 마스크 기부`,
        pcImg: Sponsorship03bX2,
        mobileImg: Sponsorship03b,
      },
    ],
  },
  {
    id: 4,
    title: `유니세프 함평군후원회`,
    dec: `유니세프 함평군후원회는 2008년 3월 창립되었습니다. 이후 17년간 지역 축제를 통해 모은 기금을 유니세프한국위원회에 꾸준히 기부하며 지역사회와 전 세계 어린이들을 위한 나눔을 이어오고 있습니다.`,
    active: [
      {
        activeName: `2008년 – 2024년 지역 축제를 통한 수익금 기부`,
        pcImg: Sponsorship04aX2,
        mobileImg: Sponsorship04a,
      },
    ],
  },
  {
    id: 5,
    title: `유니세프 서귀포시후원회`,
    dec: `유니세프 서귀포시후원회는 2011년 11월에 창립되었습니다. 2012년 9월 서귀포 칠십리 축제 내 유니세프 홍보 부스를 마련하여 모금 활동을 한 것을 시작으로, 매년 지역 축제 및 자선 골프대회를 개최하여 지역사회 내 유니세프의 이념을 홍보하고 유니세프 기금 마련에 적극 동참하고 있습니다.`,
    active: [
      {
        activeName: `2012년 – 2016년 서귀포시후원회원 이왈종 화백 판화전 판매 수익금 기부`,
        pcImg: Sponsorship05a /* 원본 사진이 작아 2x 사이즈 없음*/,
        mobileImg: Sponsorship05a,
      },
      {
        activeName: `2021년 12월 서귀포시 유니세프 협력도시 협약`,
        pcImg: Sponsorship05bX2,
        mobileImg: Sponsorship05b,
      },
      {
        activeName: `2024년 3월 제2회 제주 자선 골프대회 개최 수익금 기부`,
        pcImg: Sponsorship05cX2,
        mobileImg: Sponsorship05c,
      },
    ],
  },
  {
    id: 6,
    title: `유니세프 금정구후원회`,
    dec: `유니세프 금정구후원회는 2012년 9월에 창립되었습니다. 2018년도 6월 부산 어린이 동화구연대회 개최 등 매년 아동권리 옹호를 위한 사업에 적극 동참하고 있습니다.`,
    active: [
      {
        activeName: `2021년 10월 코로나 극복 기원 다문화 가정 어린이 마스크 전달`,
        pcImg: Sponsorship06aX2,
        mobileImg: Sponsorship06a,
      },
    ],
  },
  {
    id: 7,
    title: `유니세프 경남후원회`,
    dec: `유니세프 경남후원회는 경상남도 지역에서의 유니세프 이념을 홍보하고 아동권리를 옹호하기 위해 2019년 12월 창립되었습니다. 2020년 6월 SFA(Schools For Asia)기금 조성을 위한 제1회 경남후원회장배 자선골프대회 개최 이후 현재까지 경상남도 지역을 기반으로 하여 봉사활동 주최 및 다양한 행사 운영으로 유니세프 기금을 마련하고 다양한 방법으로 유니세프 사업을 적극 지원하고 있습니다.`,
    active: [
      {
        activeName: `2019년 5월 SFA 기금 조성을 위한 제1회 경남후원회장배 자선골프대회 개최 수익금 기부`,
        pcImg: Sponsorship07aX2,
        mobileImg: Sponsorship07a,
      },
      {
        activeName: `2020년 10월 코로나 극복 기원 창원 다문화 가정 어린이 희망키트 전달`,
        pcImg: Sponsorship07bX2,
        mobileImg: Sponsorship07b,
      },
      {
        activeName: `2021년 9월 유니세프 경남후원회 박윤규 회장 아너스클럽 약정 회원 가입`,
        pcImg: Sponsorship07cX2,
        mobileImg: Sponsorship07c,
      },
      {
        activeName: `2022년 11월 ‘제3회 유니세프 희망 걷기대회’ 개최 수익금 기부`,
        pcImg: Sponsorship07dX2,
        mobileImg: Sponsorship07d,
      },
      {
        activeName: `2023년 12월 송년콘서트 개최 수익금 기부`,
        pcImg: Sponsorship07eX2,
        mobileImg: Sponsorship07e,
      },
    ],
  },
  {
    id: 8,
    title: `유니세프 울산시후원회`,
    dec: `유니세프 울산시후원회는 2022년 12월 창립되었습니다. 지역내 아동권리 옹호와 다양한 모금 활동을 통해 유니세프 사업을 적극 지원하고 있습니다.`,
    active: [
      {
        activeName: `2022년 12월 울산시후원회 김형석 회장 아너스클럽 약정회원 가입`,
        pcImg: Sponsorship08aX2,
        mobileImg: Sponsorship08a,
      },
      {
        activeName: `2023년 2월 튀르키예 지진 긴급 구호기금 전달`,
        pcImg: Sponsorship08bX2,
        mobileImg: Sponsorship08b,
      },
    ],
  },
];

const contactData = [
  {
    id: 1,
    tit: `문의`,
    costumer: `지역후원센터`,
    tel: `051-501-0087`,
    email: `rfc@unicef.or.kr`,
  },
];

const SectionTop = styled(Section)`
  .img-map {
    width: auto;
    margin: 0 auto;
    display: block;
    max-width: 100%;
  }
  ${breakpoint(`mobile`)} {
    .img-map img {
      width: 100%;
    }
  }
`;

const SponsorCard = styled.div`
  position: relative;
  margin-bottom: 6rem;

  &:last-child {
    margin-bottom: 0 !important;
  }

  .sponsor-dec {
    margin-top: 2rem;
  }

  article {
    margin-top: 6rem;

    .active-list {
      margin-top: 2rem;
      .active-title {
        display: block;
        width: 100%;
        padding: 24px;
        font-weight: bold;
        font-size: 20px;
        position: relative;
        line-height: 1.6;
        text-align: left;
      }
      .active-content {
        img {
          width: auto;
          max-width: 100%;
        }
      }
    }
  }

  ${breakpoint(`tablet`)} {
    padding-bottom: 8.62vw;
    margin-bottom: 72px;
  }
  ${breakpoint(`mobile`)} {
    margin: 0;
    padding-bottom: 112px;
    flex-wrap: wrap;
  }
`;
const AccordionBody = styled.div`
  padding: 32px 0;
  font-size: 16px;
`;

const SponsorshipArticle: FC<PageProps> = ({ location }) => {
  return (
    <>
      <SectionTop className="by-sub-main-layout">
        <Container>
          <SectionHeader className="with-desc">
            <h2>
              <Tit size="s1-5" color="sky" weight="normal">
                <PageComponent id="title1">
                  같은 지역에 거주하는 <br />
                  사람들과 함께해보세요
                </PageComponent>
              </Tit>
            </h2>
            <p className="header-dec">
              <PageComponent id="content1">
                같은 지역에서 유니세프 모금 및 아동권리 옹호에 동참해 전 세계
                어린이들의 삶을 변화시킬 수 있습니다.
              </PageComponent>
            </p>
          </SectionHeader>
          <Image
            className="img-map"
            pcSrc={SponsorMap}
            mobileSrc={SponsorMapM}
          />
        </Container>
      </SectionTop>

      <Section className="by-sub-main-layout">
        <Container>
          <div className="sponsorship-container">
            {SponsorData.map((row) => (
              <SponsorCard>
                <h2>
                  <Tit size="s1-5" color="sky" weight="normal">
                    {row.title}
                  </Tit>
                </h2>
                <p className="sponsor-dec">{row.dec}</p>

                <article>
                  <h3>
                    <Tit size="s2">주요 활동</Tit>
                  </h3>

                  <AccordionComponent initialKey="1" className="active-list">
                    {row.active?.map((item, index) => (
                      <>
                        {item.pcImg === null ? (
                          <li id={`${index}`}>
                            <div key={index} className="active-title">
                              <Tit size="s4-1">{item.activeName}</Tit>
                            </div>
                          </li>
                        ) : (
                          <AccordionItem
                            id={`${index}`}
                            key={index}
                            toggleKey={index}
                            renderToggle={(onClick) => (
                              <button
                                type="button"
                                className="active-title"
                                onClick={onClick}
                              >
                                <Tit size="s4-1">{item.activeName}</Tit>
                              </button>
                            )}
                          >
                            <AccordionBody className="active-content">
                              <figure>
                                <Image
                                  pcSrc={item.pcImg}
                                  pcMa={1}
                                  mobileSrc={item.mobileImg}
                                />
                              </figure>
                            </AccordionBody>
                          </AccordionItem>
                        )}
                      </>
                    ))}
                  </AccordionComponent>
                </article>
              </SponsorCard>
            ))}
          </div>
        </Container>
      </Section>

      <Section className="by-sub-main-layout">
        <Container>
          <ContactUs itemData={contactData} />
        </Container>
      </Section>
    </>
  );
};
export default SponsorshipArticle;
