import LayoutWithTitle from '@/layouts/LayoutWithTitle';
import { PageProps } from 'gatsby';
import React, { FC } from 'react';
import SponsorshipArticle from '@/page-blocks/involve/corporate-and-organization/SponsorshipArticle';

const SponsorshipClone: FC<PageProps> = (pageProps) => (
  <LayoutWithTitle
    location={pageProps.location}
    title="지역후원회"
    description="for every child, UNICEF Supporters Club"
  >
    <SponsorshipArticle {...pageProps} />
  </LayoutWithTitle>
);

export default SponsorshipClone;
